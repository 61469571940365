:root {
  --color-orange-600: #f05a23;
  --color-orange-700: #cf400d;
  --color-indigo-50: #f0f1ff;
  --color-indigo-300: #b0b8fc;
  --color-indigo-500: #6863f1;
  --color-indigo-600: #5040ff;
  --color-indigo-700: #4234e0;
  --color-indigo-800: #3429ab;
  --color-indigo-900: #140078;
  --color-blue-400: #60a5fa;
  --color-red-400: #f87a71;
  --color-green-400: #34d399;
  --color-gray-100: #f3f4f6;
  --color-gray-200: #e5e7eb;
  --color-gray-300: #d1d5db;
  --color-gray-400: #9ca3af;
  --color-gray-500: #6b7280;
  --color-gray-600: #4b5563;
  --color-gray-700: #374151;
  --color-gray-800: #1f2937;
  --color-gray-900: #111827;
  --color-error: #f04030;
  --color-success: #34d399;
}
