@import "frontend-shared/normalize.css";
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "frontend-shared/colors.scss";

html {
  overflow: auto;
  width: 100%;
  height: 100%;
  font-size: 16px;
}

body {
  width: 100%;
  min-height: 100%;
  display: flex;
  font-family: "Arial";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#react-root {
  flex: 1 1 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
}
