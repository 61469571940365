.DocumentDetailPanel__htmlContainer {
  img {
    max-width: none;
  }

  ul,
  ol {
    list-style: revert;
    padding: revert;
    margin: revert;
  }
}
